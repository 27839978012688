.actives{
    
    color: #4C63BD;
    border-bottom: 3px solid #4C63BD ;
}
.activesbg{
    background-color: #4C63BD;
    color: white;
}

