.radiobox{
    appearance: none;
    position: relative;
    display: inline-block;
    cursor: pointer;

}

.radiobox:checked::before {
    position: absolute;
    content: "\f058"; 
    font-family: "Font Awesome 5 Free";
    left: -10px;
    color: #4C63BD;
   
}