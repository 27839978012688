.rounded-checkbox {
    appearance: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 2px solid #46484b15;
    cursor: pointer;
    display: inline-block;
    position: relative;
  }
  .rounded-checkbox:checked {
    border: 2px solid #4C63BD; 
  } 
  
.rounded-checkbox:checked::before {
    position: absolute;
    content: "";
    width: 14px; 
    height: 14px; 
    border-radius: 50%;
    background-color: #4C63BD;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
  