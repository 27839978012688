@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --primary: #4C63BD;
  --secondary: #E6EFF9;
  --primaryHover: #5323a5;
  --lightSlate: #F8FAFD;
  --grayText: #9B98B8;
  --dark: #333;
  --darkSlate: #E7EAF3;
  --pure: #fff;
}

body {
  font-family: 'Poppins', sans-serif;
}

.secondary-font {
  font-family: 'Roboto', sans-serif;
}

.primary-background {
  background: var(--primary);
  
}
.text-primary{
  color: var(--primary);
}

.secondary-background {
  background: var(--secondary);
}

.box-shadow {
  box-shadow: 4px 4px 10px 0px #C2CCEB80;

}

.navshadow{
  box-shadow: 0px 2px 2px 0px #00000040;

}


.small-text {
  font-size: 10px;
}

.linear-gradient {
  background: linear-gradient(to bottom, var(--primary), #C2CCEB80);
}

#select-box-input {
  padding: 6px 0 !important;
  outline: none !important; 
}

.css-t3ipsp-control {
  border: none !important;
}



.main-color:hover{
  background-color: rgba(0, 0, 0, 0.1);
}

.box{

  box-shadow:0px 0px 10px rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar {
  width: 6px; 
}

::-webkit-scrollbar-track {
  background: #E6EFF9; 
}

::-webkit-scrollbar-thumb {
  background: #4C63BD; 
  border-radius: 10px;
}

.no-warp{

  white-space: nowrap;

}


/* expand screen */

/* .expand {
  width: 100%;
  margin: auto;
}

@media only screen and (min-width: 1500px) {
  .expand {
      width: 80%;
  }
}

@media only screen and (min-width: 1700px) {
  .expand {
      width: 70%;
  }
}

@media only screen and (min-width: 1800px) {
  .expand {
      width: 60%;
  }
}

@media only screen and (min-width: 2000px) {
  .expand {
      width: 50%;
  }
}

@media only screen and (min-width: 2200px) {
  .expand {
      width: 40%;
  }
}

@media only screen and (min-width: 2400px) {
  .expand {
      width: 35%;
  }
} */