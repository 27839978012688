.custom-change{
    width: 100% !important;
}
/* loading */
.stave-btn{
   font-size: 22px;
   color: #fff;
   background-color: #28B446;
   width: 250px;
   height: 60px;
   cursor: pointer;
   border-radius: 3px;
}
.loader{
    pointer-events: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color:  #fff;
    animation: an1 1s ease infinite;

}
@keyframes an1 {
    0% {
        transform: rotate(0turn);
    }
    100%{
        transform: rotate(1turn);
    }
}