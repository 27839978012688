.activediv{
    position: relative;
    overflow: hidden;
  }
  
  .activediv::after {
    content: '';
    position: absolute;
    width: 0%; 
    height:  0.03em;
    left: 0;
    bottom: 0;
    background-color: transparent;
    transition: width 0.3s ease; 
    transform-origin: 0% 100%; 
    transition-delay: 0s; 
  }
  
  .activediv.active::after {
    width: 100%; 
    transition-delay: 0.3s; 
    background-color: #4C63BD;
  }

  .activediv.active::after {
    animation: moveFromLeft 0.3s ease; 
  }
  
  @keyframes moveFromLeft {
    0% {
      transform: translateX(0); 
    }
    100% {
      transform: translateX(100%); 
    }
  }