.custom-shape-divider-top-1715061159 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1715061159 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 150px;
}

.custom-shape-divider-top-1715061159 .shape-fill {
    fill: #FFFFFF;
}

/* .expand {
    width: 100%;
    margin: auto;
}

@media only screen and (min-width: 1500px) {
    .expand {
        width: 80%;
    }
}

@media only screen and (min-width: 1700px) {
    .expand {
        width: 70%;
    }
}

@media only screen and (min-width: 1800px) {
    .expand {
        width: 60%;
    }
}

@media only screen and (min-width: 2000px) {
    .expand {
        width: 50%;
    }
}

@media only screen and (min-width: 2200px) {
    .expand {
        width: 40%;
    }
}

@media only screen and (min-width: 2400px) {
    .expand {
        width: 35%;
    }
} */
